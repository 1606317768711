<template>
  <button class="base-button toggle-button" 
    :class="{ 'inactive':!isActive }"
    @click="handleToggle"
  >
    <img v-if="isActive" class="basic-button-icon" :src="icEyeOpen" />
    <img v-else class="basic-button-icon" :src="icEyeClosed" />
    <p v-if="isActive" class="button-text">{{ $t('VISIBLE') }}</p>
    <p v-else class="button-text">{{ $t('INVISIBLE') }}</p>
  </button>
</template>

<script setup>
import { ref } from 'vue';
import icEyeOpen from '@/assets/icons/icn_eye_open.svg';
import icEyeClosed from '@/assets/icons/icn_eye_closed.svg';

const props = defineProps({
  initialValue: {
    type: Boolean,
    default: true,
  }
});

const emits = defineEmits(['update']);

const isActive= ref(props.initialValue);

function handleToggle() {
  isActive.value = !isActive.value;
  emits('update', isActive.value);
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.variables";
@import "../../assets/css/base.mixins";

.toggle-button {
  display: flex;
  gap: rem(12);
  background: var(--blue_dark-03);
  color: #fff;
  font-size: rem(14);
  padding: rem(6) rem(16);
  width: rem(180);
  align-items: center;
}

.inactive {
  background: var(--blue_light_04);
}

.button-text {
  margin-top: rem(2);
}
</style>